<template>
  <div class="lostDetailWrap">
    <a-spin :spinning="loading" size="large" style="position: absolute; left: 50%; top: 30%"></a-spin>
    <div v-if="detailData" class="lost-refund-detail">
      <a-descriptions title="学生信息">
        <a-descriptions-item label="学生姓名"> {{ detailData.studentRespDTO.fullName }} </a-descriptions-item>
        <a-descriptions-item label="学号"> {{ detailData.studentRespDTO.code }} </a-descriptions-item>
        <a-descriptions-item label="当前班型">
          {{ getDdlLabel(scheduleOptions.classType, detailData.classType) }}
        </a-descriptions-item>
        <a-descriptions-item label="当前科目">
          {{ getDdlLabel(subjectArr, detailData.subject) }}
        </a-descriptions-item>
        <a-descriptions-item label="当前科目类型"> {{ detailData.subjectType.nodeName }} </a-descriptions-item>
        <a-descriptions-item label="当前级别"> {{ detailData.currentLevelData.nodeName }} </a-descriptions-item>
        <a-descriptions-item label="当前授课老师">
          <span v-for="item of detailData.teachersData" :key="item.uuid"
            >{{ item.fullName }},
          </span></a-descriptions-item
        >
        <a-descriptions-item label="流失前班主任"> {{ detailData.classAdminData.fullName }} </a-descriptions-item>
        <a-descriptions-item label="流失前学习规划师"> {{ detailData.consultantData.fullName }} </a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="流失信息">
        <a-descriptions-item label="流失类型">
          {{ getDdlLabel(scheduleOptions.lossRefundType, detailData.lossRefundType) }}
        </a-descriptions-item>
        <a-descriptions-item label="流失主要原因"> {{ detailData.reasonOne }} </a-descriptions-item>
        <a-descriptions-item label="流失主因II级"> {{ detailData.reasonTwo }} </a-descriptions-item>
        <a-descriptions-item label="流失主因III级"> {{ detailData.reasonThree }} </a-descriptions-item>
        <a-descriptions-item label="其他流失原因">
          {{ (detailData.reasonOther && detailData.reasonOther.join(',')) || '' }}
        </a-descriptions-item>
        <a-descriptions-item label="流失去向"> {{ detailData.direction }} </a-descriptions-item>

        <template v-if="detailData.lossRefundType === 'NOT_RENEW'">
          <a-descriptions-item label="不续费原因分类"> {{ detailData.category.join(',') }} </a-descriptions-item>
          <a-descriptions-item label="不续费决定日期">
            {{ moment(detailData.decidedTime).format('YYYY-MM-DD HH:mm') }}
          </a-descriptions-item>
          <a-descriptions-item label="已扣课时"> {{ detailData.consumedCourseUnit }} </a-descriptions-item>
        </template>
        <template v-if="detailData.lossRefundType === 'REFUND'">
          <a-descriptions-item label="退费原因分类">
            {{ (detailData.category && detailData.category.join(',')) || '' }}
          </a-descriptions-item>
          <a-descriptions-item label="退费决定日期">
            {{ moment(detailData.decidedTime).format('YYYY-MM-DD HH:mm') }}
          </a-descriptions-item>
          <a-descriptions-item label="退费状态"> {{ detailData.refundStatus }} </a-descriptions-item>
          <a-descriptions-item label="已扣课时"> {{ detailData.consumedCourseUnit }} </a-descriptions-item>
          <a-descriptions-item label="试学期退费"> {{ detailData.refundTrySemester }} </a-descriptions-item>
          <a-descriptions-item label="入学标准"> {{ detailData.entranceStandard }} </a-descriptions-item>
        </template>
      </a-descriptions>

      <a-descriptions title="挽单信息">
        <a-descriptions-item label="班主任沟通记录"> {{ detailData.classAdminContact }} </a-descriptions-item>
        <a-descriptions-item label="学习规划师沟通记录"> {{ detailData.consultantContact }} </a-descriptions-item>
        <a-descriptions-item label="教学沟通记录"> {{ detailData.teachContact }} </a-descriptions-item>
        <a-descriptions-item label="其他备注原因"> {{ detailData.otherRemark }} </a-descriptions-item>
        <a-descriptions-item label="挽单沟通描述"> {{ detailData.detainDescription }} </a-descriptions-item>
        <a-descriptions-item label="挽单沟通记录">
          <template
            v-if="
              detailData.detain && detailData.detain.classMasterRecord && detailData.detain.classMasterRecord.length > 0
            "
          >
            <a-popover
              title="沟通记录"
              v-for="item of detailData.detain.classMasterRecord"
              :key="item.contentUrl"
              placement="top"
            >
              <template slot="content">
                <img :src="item.contentUrl" alt="" style="max-width: 500px" />
              </template>
              <img :src="item.contentUrl" alt="" style="width: 50px; height: 50px; margin: 5px" />
            </a-popover>
          </template>
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions title="订单信息">
        <a-descriptions-item label="关联订单"> {{ detailData.orders }} </a-descriptions-item>
        <a-descriptions-item label="退费金额"> {{ detailData.refundAmount }} </a-descriptions-item>
        <a-descriptions-item label="退费币种"> {{ detailData.refundCurrency }} </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { getReq, loadScheduleOptions } from '@/api/schedule';
import { getDdlLabel } from '@/utils/util';

export default {
  data() {
    return {
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      getDdlLabel,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      detailData: null,
      uuid: this.$route.query.id,
      loading: false,
      scheduleOptions: {
        classType: [],
        courseType: [],
        scheduleOptions: [],
        scheduleCancelReason: [],
      },
    };
  },
  created() {
    this.loading = true;
    getReq(`/api/admin/scheduling/loss_refund/${this.uuid}`)
      .then((res) => {
        this.detailData = res.data.content;
      })
      .finally(() => {
        this.loading = false;
      });

    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });
  },
  methods: {
    moment,
    onChange(date) {
      this.form.startDateTime = date[0].format('YYYY-MM-DD HH:mm:ss');
      this.form.endDateTime = date[1].format('YYYY-MM-DD HH:mm:ss');
    },
    deleteItem(id, name) {
      const _this = this;
      this.$confirmAnt({
        title: '删除学员流失记录',
        content: `确认删除学员流失记录 ${name} 吗?`,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          _this.loading = true;
          deleteReq(`${_this.searchUrl}/${id}`, {})
            .then((res) => {
              _this.getListData();
            })
            .finally(() => {
              _this.loading = false;
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.lostDetailWrap {
  width: 100%;
  padding: 18px 20px 0;
  background-color: #fff;
  min-height: calc(100vh - 80px);
}
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}

/deep/ .ant-descriptions-row > td {
  vertical-align: text-top;
}
/deep/ .ant-descriptions-title {
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height: 16px;
    background-color: #41cc83;
    border-radius: 0px 2px 2px 0px;
  }
}
</style>
